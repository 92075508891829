.organization-mapping {
  .selectbox__indicator-separator {
    display: none;
  }

  .selectbox__control:hover {
    border-color: hsl(0,0%,80%);
    box-shadow: none;
  }
  .selectbox__control--is-focused,
  .selectbox__control--is-focused:hover {
    border-color: #85b7d9;
    box-shadow: none;
  }
  .selectbox__option--is-focused:not(.selectbox__option--is-selected) {
    background-color: inherit;
  }
  .selectbox__option--is-focused:not(.selectbox__option--is-selected):hover {
    background-color: #DEEBFF;
  }
  .selectbox__option:not(.selectbox__option--is-selected) {
    color: #000000;
  }
  .custom-option {
    border-top: 1px solid #DDDDDD;
    padding: 8px 12px;
    font-weight: bold;
    cursor: pointer;
    color: #000000;
  }
  .custom-option:hover {
    background-color: #DEEBFF;
  }
  .deleteMapping {
    margin-left: -20px;
    margin-top: 10px;
    border: 0;
    background-color: #db2828;
    color: #fff;
    border-radius: 3px;
    cursor: pointer;
  }
  .organization-header {
    margin: 0;
    padding: 0;
    span {
      margin-right: 10px;
    }
    span:last-child {
      margin-right: 0;
    }
    .organization-id {
      font-size: 0.75em;
      color: #999999;
    }
  }
  .action-buttons {
    button {
      margin-right: 15px;
    }
    button:last-child {
      margin-right: 0;
    }
  }
}
