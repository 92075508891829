.dropdown-filter {
  &.ui.menu {
    .item {
      line-height: 1.2;
    }
  }
  .clear.dropdown.icon{
    z-index: 3;
  }
}
