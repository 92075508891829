.layout {
  .main-header {
    background-color: #002540;
    color: #fff;
    height: 100px;
    padding: 0 20px;
    overflow: hidden;
    .header-container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 100%;
      width: 100%;
      .header-left-group, .header-right-group {
        display: flex;
        align-items: center;
      }
      .header-item {
        margin: 10px;
        font-size: 16px;
        line-height: 1;
        a,
        a:active,
        a:hover,
        a:visited {
          color: #fff;
        }
        .action-link {
          cursor: pointer;
        }
      }
      .header-item-separator {
        padding: 15px 0 15px 1px;
        margin: 0 10px;
        background-color: white;
      }
      .logo {
        max-height:100%;
        width: auto;
      }
    }
  }
  .content {
    padding-top: 40px;
    padding-bottom: 40px;
    position: relative;
    min-height: calc(100vh - 100px);
  }
}
